import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";
import canadapic from "../../assets/canadavisa.png";
import uk from "../../assets/unitedkingdom.jpg";
import ireland from "../../assets/ireland.jpg";
import Swal from "sweetalert2";
import schengen from "../../assets/schegenvisa.png";
import _default from "../../assets/airplane.png";
import { useNavigate, useParams } from "react-router-dom";

const OrderDetails = () => {
  const { orderID } = useParams();
  const [isScrolled, setIsScrolled] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const [rating, setRating] = useState(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [visaResult, setVisaResult] = useState("");
  const [postApproval, setPostApproval] = useState(true);
  const [submitStatus, setSubmitStatus] = useState({
    show: false,
    success: false,
    message: "",
  });
  const [review, setReview] = useState(null);

  useEffect(() => {
    if (!orderID) {
      console.error("No orderID available");
      return;
    }
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("accessToken");

        if (!token) {
          handleInvalidToken("No access token found. Please log in.");
          return;
        }

        console.log("Making API call..."); // Debug log
        const response = await axios.get(
          `${config.apiBaseUrl}/api/orders/detail/${orderID}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data) {
          setOrderData(response.data);

          if (response.data.order_status === "Completed") {
            try {
              const reviewRes = await axios.get(
                `${config.apiBaseUrl}/api/orders/${orderID}/review`,
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              );
              if (reviewRes.data) {
                setReview(reviewRes.data);
              }
            } catch (reviewError) {
              console.error("Error fetching review:", reviewError);
            }
          }
        }
        setLoading(false);
      } catch (error) {
        // If the error is related to token expiration, handle it
        if (error.response?.status === 401 || error.response?.status === 403) {
          handleInvalidToken("Session expired or unauthorized. Please log in.");
        } else {
          console.error("Error details:", {
            message: error.message,
            response: error.response?.data,
            status: error.response?.status,
          });
        }
        setLoading(false);
      }
    };
    const handleInvalidToken = (message) => {
      // Clear the token from localStorage
      localStorage.removeItem("accessToken");

      // Show a toast message
      Swal.fire({
        icon: "error",
        title: "Authentication Error",
        text: message,
        timer: 3000,
        timerProgressBar: true,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
      });

      // Redirect to login
      navigate("/login");
    };

    fetchData();
  }, [orderID, navigate]);

  const handleSubmitReview = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.post(
        `${config.apiBaseUrl}/api/bookings/review`,
        {
          bookingId: orderData.bookid,
          stars: rating,
          visaResult: visaResult,
          feedback: review,
          postApproval: postApproval,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (
        response.status === 201 &&
        response.data.message === "Review saved successfully"
      ) {
        setSubmitStatus({
          show: true,
          success: true,
          message: "Thank you! Your review has been submitted successfully.",
        });

        // Refresh order data after 2 seconds
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setSubmitStatus({
          show: true,
          success: false,
          message:
            "Something went wrong while submitting the review. Please try again.",
        });
      }
    } catch (error) {
      let errorMessage = "Failed to submit review. Please try again.";

      if (error.response) {
        switch (error.response.status) {
          case 400:
            errorMessage = "Review already exists for this booking.";
            break;
          case 401:
            errorMessage = "Session expired. Please login again.";
            break;
          case 403:
            errorMessage = "You are not authorized to review this booking.";
            break;
          case 500:
            errorMessage = "Server error. Please try again later.";
            break;
          default:
            errorMessage = error.response.data.message || errorMessage;
        }
      }

      setSubmitStatus({
        show: true,
        success: false,
        message: errorMessage,
      });
    }
  };

  const visaImages = {
    "Canada Visa": canadapic,
    "Schengen Visa": schengen,
    "Ireland Visa": ireland,
    "United Kingdom Visa": uk,
  };

  const getVisaImage = (visatype) => visaImages[visatype] || _default;
  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 50);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-lg text-gray-600">Loading order details...</div>
      </div>
    );
  }
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Sticky Header */}
      <div
        className={`fixed top-0 left-0 right-0 z-50 transition-all duration-500 ${
          isScrolled
            ? "bg-white shadow-lg opacity-100"
            : "bg-transparent opacity-0 pointer-events-none"
        }`}
      >
        <div className="container mx-auto px-4">
          <div className="flex items-center gap-4 py-4 h-16">
            <div className="flex items-center gap-4 transform transition-all duration-500">
              <img
                src={getVisaImage(orderData?.visatype)}
                alt={orderData?.visatype}
                className="w-12 h-12 rounded-lg object-cover"
              />
              <h1 className="text-xl font-bold">
                {orderData
                  ? `${orderData.visatype} Order #${orderData.bid}`
                  : "Loading..."}
              </h1>
            </div>
          </div>
        </div>
      </div>

      {/* Hero Section */}
      <div className="relative h-96">
        <img
          src={getVisaImage(orderData?.visatype)}
          alt={`${orderData?.visatype || "Visa"} Application`}
          className="w-full h-full object-cover"
        />

        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/50">
          <div className="container mx-auto px-4 h-full flex flex-col justify-end pb-8">
            <h1 className="text-4xl font-bold text-white mb-2">
              {orderData
                ? `${orderData.visatype} Order #${orderData.bid}`
                : "Loading..."}
            </h1>
            <p className="text-white/80">
              {orderData
                ? `Ordered on: ${new Date(
                    orderData.createdon
                  ).toLocaleString()}`
                : ""}
            </p>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Order Details */}
          <div className="md:col-span-2 space-y-6">
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h2 className="text-2xl font-semibold mb-4">Order Information</h2>
              <div className="grid grid-cols-2 gap-4">
                <InfoItem label="Full Name" value={orderData?.name} />
                <InfoItem label="Plan Type" value={orderData?.plan} />
                <InfoItem label="Nationality" value={orderData?.nation} />
                <InfoItem label="Occupation" value={orderData?.occ} />
                <InfoItem label="Drive Link" value={orderData?.drive} />
                <InfoItem label="Other Info" value={orderData?.anything} />
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-sm p-6">
              <h2 className="text-2xl font-semibold mb-4">Payment Details</h2>
              <div className="flex items-center gap-2 mb-4">
                {orderData?.payment_status === "Success" ? (
                  <>
                    <div className="bg-green-100 p-2 rounded-full">
                      <svg
                        className="w-4 h-4 text-green-600"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </div>
                    <span className="text-green-600 font-medium">
                      Payment Successful
                    </span>
                  </>
                ) : orderData?.payment_status === "Pending" ? (
                  <>
                    <div className="bg-yellow-100 p-2 rounded-full">
                      <svg
                        className="w-4 h-4 text-yellow-600"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                    <span className="text-yellow-600 font-medium">
                      Payment Pending
                    </span>
                  </>
                ) : (
                  <>
                    <div className="bg-red-100 p-2 rounded-full">
                      <svg
                        className="w-4 h-4 text-red-600"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </div>
                    <span className="text-red-600 font-medium">
                      Payment {orderData?.payment_status}
                    </span>
                  </>
                )}
              </div>
              <div className="grid grid-cols-2 gap-4">
                <InfoItem
                  label="Amount Paid"
                  value={`₹${orderData?.amount || 0}`}
                />
                <InfoItem
                  label="Payment ID"
                  value={orderData?.razid || "N/A"}
                />
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-sm p-6">
              <h2 className="text-2xl font-semibold mb-4">Appointments</h2>
              <div className="space-y-4">
                <AppointmentCard
                  name={orderData?.app_name || "John Doe"}
                  date={new Date(orderData?.datetime).toLocaleDateString()}
                  time={new Date(orderData?.datetime).toLocaleTimeString()}
                  status={orderData?.appointment_status || "Confirmed"}
                  link={orderData?.link || "N/A"}
                  email={orderData?.email || "N/A"}
                  phone={orderData?.phone || "N/A"}
                />
              </div>
            </div>

            {orderData?.order_status === "Review" ? (
              <div className="bg-white rounded-xl shadow-sm p-6">
                <h2 className="text-2xl font-semibold mb-6">Leave a Review</h2>
                <form onSubmit={handleSubmitReview} className="space-y-6">
                  {/* Visa Application Result */}
                  <div>
                    <label className="block text-lg font-medium mb-3">
                      What was your visa application result?
                    </label>
                    <div className="flex gap-4">
                      {["Approved", "Rejected", "Awaiting"].map((status) => (
                        <label key={status} className="flex items-center">
                          <input
                            type="radio"
                            name="visaResult"
                            value={status}
                            checked={visaResult === status}
                            onChange={(e) => setVisaResult(e.target.value)}
                            className="w-4 h-4 text-blue-600"
                            required
                          />
                          <span className="ml-2">{status}</span>
                        </label>
                      ))}
                    </div>
                  </div>

                  {/* Overall Experience Rating */}
                  <div>
                    <label className="block text-lg font-medium mb-3">
                      How was your overall experience with us?
                    </label>
                    <div className="flex gap-2 mb-4">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <button
                          key={star}
                          type="button"
                          onClick={() => setRating(star)}
                          className="focus:outline-none"
                        >
                          <svg
                            className={`w-8 h-8 ${
                              star <= rating
                                ? "fill-yellow-400 text-yellow-400"
                                : "text-gray-300"
                            }`}
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
                          </svg>
                        </button>
                      ))}
                    </div>
                    <div className="text-sm text-gray-500 mt-8">
                      Your rating: {rating} out of 5
                    </div>
                  </div>

                  {/* Feedback */}
                  <div>
                    <label className="block text-lg font-medium mb-3">
                      Share your experience
                      <span className="text-red-500 ml-1">*</span>
                    </label>
                    <textarea
                      value={review}
                      onChange={(e) => setReview(e.target.value)}
                      placeholder="Please share your detailed feedback about your experience with our services..."
                      className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 min-h-[120px]"
                      required
                    />
                  </div>

                  {/* Permission Checkbox */}
                  <div className="flex items-start gap-2">
                    <input
                      type="checkbox"
                      id="permission"
                      defaultChecked
                      checked={postApproval}
                      onChange={(e) => setPostApproval(e.target.checked)}
                      className="mt-1 w-4 h-4 text-blue-600 rounded"
                      required
                    />
                    <label
                      htmlFor="permission"
                      className="text-sm text-gray-600"
                    >
                      I give permission to Viszapp to display my name, visa
                      application result, rating, and feedback on their website
                      and other marketing materials.
                    </label>
                  </div>

                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className="px-12 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
        transition-colors font-semibold text-lg"
                    >
                      Submit Review
                    </button>
                  </div>
                </form>

                {submitStatus.show && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div
                      className={`bg-white p-6 rounded-lg shadow-xl max-w-md w-full mx-4 ${
                        submitStatus.success
                          ? "border-l-4 border-green-500"
                          : "border-l-4 border-red-500"
                      }`}
                    >
                      <div className="flex items-center gap-3">
                        {submitStatus.success ? (
                          <svg
                            className="w-6 h-6 text-green-500"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            className="w-6 h-6 text-red-500"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        )}
                        <h3
                          className={`text-lg font-medium ${
                            submitStatus.success
                              ? "text-green-800"
                              : "text-red-800"
                          }`}
                        >
                          {submitStatus.success ? "Success!" : "Error"}
                        </h3>
                      </div>
                      <p className="mt-2 text-gray-600">
                        {submitStatus.message}
                      </p>
                      <button
                        onClick={() =>
                          setSubmitStatus({ ...submitStatus, show: false })
                        }
                        className="mt-4 px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200 transition-colors"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : orderData?.order_status === "Completed" && review ? (
              // Show Submitted Review
              <div className="bg-white rounded-xl shadow-sm p-6">
                <h2 className="text-2xl font-semibold mb-4">Your Review</h2>
                <div className="space-y-4">
                  <div>
                    <h3 className="text-lg font-medium mb-2">
                      Visa Application Result
                    </h3>
                    <span className="px-3 py-1 rounded-full text-sm bg-blue-100 text-blue-800">
                      {review.visaresult || "N/A"}
                    </span>
                  </div>

                  <div>
                    <h3 className="text-lg font-medium mb-2">
                      Overall Experience
                    </h3>
                    <div className="flex gap-1 mb-1">
                      {[...Array(5)].map((_, index) => (
                        <svg
                          key={index}
                          className={`w-6 h-6 ${
                            index < review.rating
                              ? "fill-yellow-400 text-yellow-400"
                              : "text-gray-300"
                          }`}
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
                        </svg>
                      ))}
                    </div>
                    <p className="text-sm text-gray-500">
                      {review.rating} out of 5
                    </p>
                  </div>

                  <div>
                    <h3 className="text-lg font-medium mb-2">Your Feedback</h3>
                    <p className="text-gray-600">{review.feedback}</p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          {/* Order Status */}
          {/* Order Status */}
          <div className="md:col-span-1">
            <div className="bg-white rounded-xl shadow-sm p-6 sticky top-24">
              <h2 className="text-2xl font-semibold mb-4">Order Status</h2>
              <div className="space-y-4">
                <StatusStep
                  title="Order Placed"
                  date={
                    orderData?.order_status !== "Payment Due" &&
                    orderData?.createdon
                      ? new Date(orderData.createdon).toLocaleDateString(
                          "en-GB",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )
                      : ""
                  }
                  completed={
                    orderData?.order_status === "processing" ||
                    orderData?.order_status === "Completed" ||
                    orderData?.order_status === "Review"
                  }
                  pending={orderData?.order_status === "Payment Due"}
                  cancelled={orderData?.order_status === "Cancelled"}
                />

                <StatusStep
                  title="Documents Verified"
                  date={
                    orderData?.order_status !== "Payment Due" &&
                    orderData?.createdon
                      ? new Date(
                          new Date(orderData.createdon).getTime() +
                            6 * 60 * 60 * 1000
                        ).toLocaleDateString("en-GB", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })
                      : ""
                  }
                  completed={
                    orderData?.order_status === "processing" ||
                    orderData?.order_status === "Completed" ||
                    orderData?.order_status === "Review"
                  }
                  cancelled={orderData?.order_status === "Cancelled"}
                />

                <StatusStep
                  title="Consultation Scheduled"
                  date={
                    orderData?.order_status !== "Payment Due" &&
                    orderData?.datetime
                      ? new Date(orderData.datetime).toLocaleDateString(
                          "en-GB",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )
                      : ""
                  }
                  completed={
                    orderData?.order_status !== "Payment Due" &&
                    orderData?.appointment_status === "Completed"
                  }
                  pending={
                    orderData?.order_status !== "Payment Due" &&
                    (orderData?.appointment_status === "Scheduled" ||
                      orderData?.appointment_status === "Confirmed")
                  }
                  cancelled={orderData?.appointment_status === "Cancelled"}
                />

                <StatusStep
                  title="Awaiting Review"
                  date={
                    (orderData?.order_status === "Review" ||
                      orderData?.order_status === "Completed") &&
                    orderData?.updatedon &&
                    orderData?.appointment_status === "Completed"
                      ? new Date(orderData.updatedon).toLocaleDateString(
                          "en-GB",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )
                      : ""
                  }
                  completed={orderData?.order_status === "Completed"}
                  pending={
                    orderData?.appointment_status === "Completed" ||
                    orderData?.order_status === "Review"
                  }
                  cancelled={orderData?.order_status === "Cancelled"}
                />

                <StatusStep
                  title="Completed"
                  date={
                    orderData?.order_status === "Completed" &&
                    orderData?.updatedon
                      ? new Date(orderData.updatedon).toLocaleDateString(
                          "en-GB",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )
                      : ""
                  }
                  completed={orderData?.order_status === "Completed"}
                  pending={orderData?.order_status === "Review"}
                  cancelled={orderData?.order_status === "Cancelled"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const InfoItem = ({ label, value }) => (
  <div>
    <p className="text-gray-600 text-sm">{label}</p>
    {label === "Drive Link" ? (
      value ? (
        <a
          href={value}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center gap-2 px-3 py-1.5 mt-1 text-sm font-medium text-blue-600 bg-blue-50 hover:bg-blue-100 rounded-lg transition-colors duration-200 group"
        >
          <svg
            className="w-4 h-4 group-hover:scale-110 transition-transform duration-200"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
            />
          </svg>
          Open Drive Folder
        </a>
      ) : (
        <span className="font-medium">N/A</span>
      )
    ) : (
      <p className="font-medium">{value || "N/A"}</p>
    )}
  </div>
);

const AppointmentCard = ({ name, date, time, status, link, email, phone }) => (
  <div className="border rounded-lg p-4">
    <div className="flex justify-between items-start mb-4">
      <div>
        <h3 className="font-semibold">{name}</h3>
        <div className="flex items-center gap-2 text-gray-600">
          <svg
            className="w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
          <span>
            {date} at {time}
          </span>
        </div>
      </div>
      <span
        className={`px-3 py-1 rounded-full text-sm ${
          status === "Completed"
            ? "bg-green-100 text-green-800"
            : status === "Confirmed"
            ? "bg-yellow-100 text-yellow-800"
            : status === "Cancelled"
            ? "bg-red-100 text-red-800"
            : "bg-blue-100 text-blue-800"
        }`}
      >
        {status}
      </span>
    </div>
    <div className="space-y-2 text-sm text-gray-600">
      <div className="flex items-center gap-2">
        <svg
          className="w-4 h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
          />
        </svg>
        {status === "Scheduled" ? (
          <span className="text-gray-500 italic">Will be updated soon</span>
        ) : (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline"
          >
            {link}
          </a>
        )}
      </div>
      <div className="flex items-center gap-2">
        <svg
          className="w-4 h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
          />
        </svg>
        <a href={`mailto:${email}`} className="hover:underline">
          {email}
        </a>
      </div>
      <div className="flex items-center gap-2">
        <svg
          className="w-4 h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
          />
        </svg>
        <a href={`tel:${phone}`} className="hover:underline">
          {phone}
        </a>
      </div>
    </div>
  </div>
);

const StatusStep = ({ title, date, completed, pending, cancelled }) => (
  <div className="flex gap-4">
    <div className="flex flex-col items-center">
      <div
        className={`w-8 h-8 rounded-full flex items-center justify-center ${
          completed
            ? "bg-green-100"
            : pending
            ? "bg-yellow-100"
            : cancelled
            ? "bg-red-100"
            : "bg-gray-100"
        }`}
      >
        {completed ? (
          <svg
            className="w-5 h-5 text-green-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        ) : pending ? (
          <svg
            className="w-5 h-5 text-yellow-600 animate-spin"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        ) : cancelled ? (
          <svg
            className="w-5 h-5 text-red-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        ) : (
          <svg
            className="w-5 h-5 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        )}
      </div>
      {date && <div className="w-px h-full bg-gray-200 mt-2"></div>}
    </div>
    <div className="flex-1 pb-8">
      <p className="font-medium">{title}</p>
      {date && <p className="text-sm text-gray-600">{date}</p>}
    </div>
  </div>
);

export default OrderDetails;
