import React, { useEffect, useRef, useState } from 'react';
import HeroSection from './HeroSection';
import Header from './Header';
import TestimonialsSection from './TestimonialSection';
import Pricing from './Pricing';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';

const Home = () => {
  const [showBanner, setShowBanner] = useState(true);
  const pricingRef = useRef(null);
  const location = useLocation();

  const handleCloseBanner = () => setShowBanner(false);

  useEffect(() => {
    if (location.state && location.state.scrollToPricing) {
      pricingRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.state]);

  return (
    <div>
 {showBanner && (
 <div 
   style={{ 
     background: "linear-gradient(rgba(0, 0, 128, 1), rgba(0, 0, 61, 1))" 
   }}
   className="text-white py-4 px-6 flex flex-col md:flex-row items-center justify-center relative"
 >
   <div className="max-w-4xl mx-auto text-center space-y-1">
     <p className="text-base md:text-lg font-medium">
       <span className="text-yellow-300">Dear Applicants:</span> Year-end winter break from 
       <span className="font-semibold"> Dec 20, 2024 - Jan 6, 2025</span>
     </p>
     <p className="text-sm md:text-base text-gray-200">
       No bookings or support during this time.
       <a 
         href="https://x.com/viszapp/status/1863467998637326561" 
         target="_blank" 
         rel="noopener noreferrer" 
         className="ml-2 text-yellow-300 hover:text-yellow-400 underline decoration-yellow-400/50 hover:decoration-yellow-400 transition-colors"
       >
         Learn More →
       </a>
     </p>
   </div>
   <button 
     onClick={handleCloseBanner}
     className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-300 hover:text-white transition-colors"
     aria-label="Close Banner"
   >
     <svg 
       className="w-5 h-5" 
       fill="none" 
       stroke="currentColor" 
       viewBox="0 0 24 24"
     >
       <path 
         strokeLinecap="round" 
         strokeLinejoin="round" 
         strokeWidth={2} 
         d="M6 18L18 6M6 6l12 12" 
       />
     </svg>
   </button>
 </div>
)}
      <Header pricingRef={pricingRef} />
      <HeroSection />
      <TestimonialsSection />
      <div ref={pricingRef}>
        <Pricing />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
