import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import config from "../../config";

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState("All Orders");

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        if (!token) {
          handleInvalidToken("No access token found. Please log in.");
          return;
        }

        const response = await axios.get(`${config.apiBaseUrl}/api/orders`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data) {
          setOrders(response.data);
        } else {
          console.error("Unexpected API response structure:", response.data);
          setOrders([]);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching orders:", error);

        if (error.response?.status === 401 || error.response?.status === 403) {
          handleInvalidToken("Session expired or unauthorized. Please log in.");
        } else {
          console.error("Error details:", {
            message: error.message,
            response: error.response?.data,
            status: error.response?.status,
          });
          setLoading(false);
        }
      }
    };

    const handleInvalidToken = (message) => {
      // Clear the token from localStorage
      localStorage.removeItem("accessToken");

      // Show a toast message
      Swal.fire({
        icon: "error",
        title: "Authentication Error",
        text: message,
        timer: 3000,
        timerProgressBar: true,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
      });

      // Redirect to login
      navigate("/login");
    };

    fetchOrders();
  }, [navigate]);
  const getStatusColor = (status) =>
    ({
      Completed: "bg-green-600",
      processing: "bg-indigo-500",
      "Payment Due": "bg-gray-500",
      Review: "bg-yellow-500",
      Cancelled: "bg-red-600",
    }[status] || "bg-gray-500");

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full text-lg">
        Loading order history...
      </div>
    );
  }

  const filteredOrders = orders.filter((order) =>
    selectedStatus === "All Orders" ? true : order.status === selectedStatus
  );

  return (
    <div className="bg-white rounded-2xl p-6 shadow-lg w-full">
      <div className="flex flex-col gap-8">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-semibold text-gray-800">
            Active Orders
          </h1>
          <select
            className="border text-sm border-gray-200 bg-gray-50 text-gray-600 rounded-lg px-3 py-1.5"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            <option>All Orders</option>
            <option>Completed</option>
            <option>processing</option>
            <option>Payment Due</option>
            <option>Review</option>
            <option>Cancelled</option>
          </select>
        </div>

        {filteredOrders.length === 0 ? (
          <div className="flex flex-col items-center justify-center py-16">
            <div className="w-16 h-16 rounded-full bg-gray-100 flex items-center justify-center mb-4">
              <svg
                className="w-8 h-8 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
            </div>
            <h3 className="text-lg font-medium text-gray-800 mb-2">
              No Orders Found
            </h3>
            <p className="text-gray-500 text-sm mb-4">
              Try changing the filter or start a new application
            </p>
            <button
              onClick={() => (window.location.href = "/visaSelection")}
              className="px-4 py-2 bg-blue-600 text-white text-sm rounded-lg hover:bg-blue-700 transition-all"
            >
              Apply Now
            </button>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
            {filteredOrders.map((order) => (
              <div
                key={order.bookid}
                className="relative group rounded-xl border border-gray-100 min-h-[280px] min-w-[320px]
                            hover:shadow-xl hover:border-gray-200 hover:-translate-y-1 
                            transition-all duration-300 bg-white cursor-pointer"
              >
                <div
                  className={`absolute top-0 left-0 w-1 h-full ${getStatusColor(
                    order.status
                  )}`}
                />

                <div className="p-6 flex flex-col justify-between h-full">
                  <div className="space-y-6">
                    <div className="flex justify-between items-start">
                      <div>
                        <p className="text-gray-400 text-xs mb-1">
                          Order ID: #{order.bid}
                        </p>
                        <h3 className="text-lg font-semibold text-gray-800">
                          {order.visatype}
                        </h3>
                      </div>
                      <span
                        className={`px-2.5 py-1 rounded-full text-xs font-medium ${getStatusColor(
                          order.status
                        )} 
                                   bg-opacity-10 text-gray-700`}
                      >
                        {order.status}
                      </span>
                    </div>

                    <div className="space-y-4">
                      <div>
                        <p className="text-gray-400 text-sm mb-1">Applicant</p>
                        <p className="text-gray-700 font-medium">
                          {order.app_name}
                        </p>
                      </div>

                      <div className="flex justify-between items-end">
                        <div>
                          <p className="text-gray-400 text-sm mb-1">Plan</p>
                          <div className="flex items-center gap-2">
                            <div className="w-1.5 h-1.5 rounded-full bg-blue-500" />
                            <p className="text-gray-700">{order.plan}</p>
                          </div>
                        </div>
                        <div className="text-right">
                          <p className="text-gray-400 text-sm mb-1">
                            Ordered On
                          </p>
                          <p className="text-gray-700">
                            {new Date(order.createdon).toLocaleDateString()}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    onClick={() =>
                      (window.location.href = `/dashboard/orders/${order.bookid}`)
                    }
                    className="w-full mt-6 py-2.5 rounded-lg text-sm font-medium text-blue-600
                                   hover:bg-blue-600 hover:text-white border border-blue-200
                                   transition-all duration-300 group-hover:shadow-md"
                  >
                    View Details
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {filteredOrders.length > 0 && (
        <div className="mt-8 flex justify-center">
          <button
            onClick={() => setSelectedStatus("All Orders")}
            className="px-4 py-2 rounded-lg text-sm text-gray-600 hover:bg-gray-50 transition-all"
          >
            View All Orders
          </button>
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
