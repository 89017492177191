// src/Cancellation.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Cancellation = () => {
  return (
    <>
      <Header />
      <div className="bg-white py-12 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-bold text-gray-900 mb-8">Cancellation Policy</h1>
          <div className="prose prose-lg">
            <p>This cancellation policy outlines the terms and conditions for cancelling services provided by Viszapp.</p>
            <h2 className="text-2xl font-bold mt-8 mb-4">Cancellation Process</h2>
            <p>To cancel a service, please contact us at <a href="mailto:support@viszapp.com" className="text-blue-600">support@viszapp.com</a>. Provide your order ID, name, service details, and reason for cancellation.</p>
            <h2 className="text-2xl font-bold mt-8 mb-4">Refund Policy</h2>
            <p>Refunds, if applicable, will be processed if the request is sent within one working day. The final decision is held by us.</p>
            <h2 className="text-2xl font-bold mt-8 mb-4">Cancellation Fees</h2>
            <p>1. A strict 20% cancellation fee applies if no calls have been made.</p>
            <p>2. No refunds will be issued for no-shows, which are defined as arriving more than 10 minutes late to the scheduled call. In such cases, rescheduling may be considered if requested within 24 hours of the original appointment time. Please note that no-shows will also result in the deduction of the missed session from your selected package. For instance, three no-shows will forfeit the A to Z package, while a single no-show will forfeit the Get Reviewed plan.</p>
            <p>3. If you attend the first call and decide not to proceed, you must provide a cancellation reason within 24 hours to be eligible for a refund, subject to a flat cancellation charge of ₹1250.</p>
            <br/>
            <p>*5% Standard Refund processing fee which is charged by payment gateway.</p>
            <h2 className="text-2xl font-bold mt-8 mb-4">Contact Us</h2>
            <p>If you have any questions about this Cancellation Policy, please contact us at <a href="mailto:support@viszapp.com" className="text-blue-600">support@viszapp.com</a>.</p>
            <p className="mt-8">This policy is effective as of 01th Dec 2024.</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Cancellation;
